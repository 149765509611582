import type { VebraPrice } from '~/types'

export function usePrice() {
  const numberToPrice = (price: number) => {
    const searchStore = useSearchStore()
    const { selectedCurrency } = storeToRefs(searchStore)
    // a function that takes a number and returns a string using k for thousands and m for millions
    // it's only used on the modal search form

    // if price is not present, return 'Any'
    if (!price) {
      return 'Any'
    }

    // if price is not a number, return 'Any'
    if (Number.isNaN(price)) {
      return 'Any'
    }

    if (price >= 99999999) {
      return 'Any'
    }
    if (price < 1000) {
      return `${selectedCurrency.value.symbol}${price}`
    }
    if (price >= 1000 && price < 1000000) {
      return `${selectedCurrency.value.symbol}${(price / 1000).toFixed(0)}k`
    }
    if (price >= 1000000) {
      return `${selectedCurrency.value.symbol}${(price / 1000000).toFixed(0)}m`
    }
    return selectedCurrency.value.symbol + price.toFixed(0)
  }

  const currencyConvert = (price: number) => {
    const searchStore = useSearchStore()
    const { selectedCurrency } = storeToRefs(searchStore)

    return price * selectedCurrency.value.rate
  }

  // this does not use the numberToPrice function, instead it returns a full length price
  // it is used for all prices throughout the site
  const format = (price: VebraPrice) => {
    const searchStore = useSearchStore()
    const { selectedCurrency } = storeToRefs(searchStore)
    if (!price || !price.amount) {
      return ''
    }
    if (price.display === 'no') {
      return 'POA'
    }

    const options = {
      style: 'currency',
      currency: selectedCurrency.value.text ?? 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }

    const toLocaleCurrency = (amount: number) => {
      return amount.toLocaleString(selectedCurrency.value.locale ?? 'en-GB', options)
    }

    const exchangeRatePrice = price.amount * selectedCurrency.value.rate

    return `${toLocaleCurrency(exchangeRatePrice)}`
  }

  // Used on the propert page
  const propertyStatus = (database: string | undefined, webStatus: string | undefined) => {
    // SALES
    if (!database || !webStatus) {
      return undefined
    }

    if (database === '1' || database === '5' || database === '6' || database === '7' || database === '15') {
      if (webStatus === '1') {
        return 'Under Offer'
      }
      if (webStatus === '2') {
        return 'Sold'
      }
      if (webStatus === '3') {
        // 'Sold Subject to Contract'
        return 'Sold'
      }
      if (webStatus === '4') {
        return 'For Sale By Auction'
      }
    }

    // LETTINGS
    if (database === '2' || database === '41' || database === '118') {
      if (webStatus === '1' || webStatus === '101') {
        return 'Let Agreed'
      }
      if (webStatus === '2' || webStatus === '102') {
        // 'Under Offer'
        return 'Let Agreed'
      }
      if (webStatus === '3' || webStatus === '103') {
        // 'Reserved'
        return 'Let Agreed'
      }
      if (webStatus === '4' || webStatus === '104') {
        // 'Let Agreed'
        return 'Let Agreed'
      }
    }
    return undefined
  }

  const currencyOptionsExport = () => {
    return [
      {
        id: 'gbp',
        locale: 'en-GB',
        text: 'GBP',
        symbol: '£',
        rate: 1, // the default rates (we'll override these with the actual rates later)
      },
      {
        id: 'usd',
        locale: 'en-US',
        text: 'USD',
        symbol: '$',
        rate: 1.253, // the default rates (we'll override these with the actual rates later)
      },
      {
        id: 'eur',
        locale: 'de-DE',
        text: 'EUR',
        symbol: '€',
        rate: 1.17, // the default rates (we'll override these with the actual rates later)
      },
    ]
  }

  return {
    format,
    propertyStatus,
    numberToPrice,
    currencyOptionsExport,
    currencyConvert,
  }
}
