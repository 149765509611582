import { defineStore } from 'pinia'
import type { Currency, Search } from '~/types'

export const useSearchStore = defineStore({
  id: 'search',
  persist: {
    storage: persistedState.localStorage,
  },

  state: () => ({
    search: <Search>{
      // this is the default search:
      mapSearch: false,
      location: {
        text: '',
        n: 52.4,
        e: -1,
        s: 51.5,
        w: -2.5,
      },
      buyRent: 'buy',
      minprice: 0,
      maxprice: 99999999,
      bedrooms: 0,
      // sortOrder: 'uploaded desc',
      sortOrder: 'closest',
      view: 'map',
    },
    selectedCurrency: <Currency> {
      text: 'GBP',
      symbol: '£',
      rate: 1,
      id: 'gbp',
      locale: 'en-GB',
    },

  }),

  actions: {
    async setSearch(search: Search) {
      // set the search object by overriding any existing values with new ones
      // use merge instead of assign to keep the reactivity
      Object.assign(this.search, search)
    },
    async setCurrency(currency: string) {
      // set the selected currency
      currency = currency.toLowerCase()
      const { currencyOptionsExport } = usePrice()
      const selectedCurrency = currencyOptionsExport().find(c => c.id === currency)

      // route guard
      if (!selectedCurrency) {
        console.log('currency not found')
        return
      }

      // if gbp, the rate is always 1 so return
      if (currency === 'gbp') {
        this.selectedCurrency = selectedCurrency
        return selectedCurrency
      }

      // if not gbp, get the rates from storage

      const rates: any = await $fetch('/api/currency/get')
      // console.log('rates from storage', rates)
      // if the rates are not in storage then return
      if (!rates) {
        this.selectedCurrency = selectedCurrency
        return selectedCurrency
      }
      const rate = rates[selectedCurrency.text]
      // console.log('rate', rate)
      // if the rate is not in the rates object then return
      if (!rate) {
        this.selectedCurrency = selectedCurrency
        return selectedCurrency
      }
      // everything is good so set the rate to the latest stored rate
      // console.log('setting rate')
      selectedCurrency.rate = rate
      this.selectedCurrency = selectedCurrency
    },
  },
})
